import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { LocationUrls } from '../../../core/navigation';
import { AccountScreen } from '../Account';
import { CloudDVRHelp, HelpScreen, ParentalControlHelp } from '../Help';
import { ForgottenPin, SettingParentalLocks, TurningParentalOnOff } from '../Help/ParentalControl';
import { InfoScreen } from '../Info';
import { MainSettings } from '../MainSettings';
import { MyShowsOptions } from '../MyShowsOptions';
import { OnePassAndRecordingOptions } from '../OnePassAndRecordingOptions';
import { OnePassManagerScreen } from '../OnePassManager';
import { ParentalControls } from '../ParentalControls';
import { HideAdultContent } from '../ParentalControls/HideAdultContent';
import { SetRatingLimits } from '../ParentalControls/SetRatingLimits';
import { UserPreferences } from '../UserPreferences';
import { MenuLanguageScreen } from '../UserPreferences/MenuLanguage';
import { UserPrefVideoWindowScreen } from '../UserPreferences/UserPrefVideoWindowScreen';

type TSettingsRoute = {
    path: string;
    element: React.ReactElement;
};
const SettingsRoutes = () => {
    const settingsRoutes: Array<TSettingsRoute> = [
        {
            path: '/',
            element: <MainSettings />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.ONEPASS_MANAGER_SETTINGS_SCREEN,
            element: <OnePassManagerScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.USER_PREFERENCES_SCREEN,
            element: <UserPreferences />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.MENU_LANGUAGE_SCREEN,
            element: <MenuLanguageScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.USER_PREF_VIDEO_WINDOW_SCREEN,
            element: <UserPrefVideoWindowScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.ONEPASS_AND_RECORDINGS_OPTIONS_MENU_SCREEN,
            element: <OnePassAndRecordingOptions />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.MY_SHOWS_OPTIONS_MENU_SCREEN,
            element: <MyShowsOptions />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_HELP_CONTROLS_ON_OFF,
            element: <TurningParentalOnOff />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_HELP_LOCKS_LIMITS,
            element: <SettingParentalLocks />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_HELP_FORGOTTEN_PIN,
            element: <ForgottenPin />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_HIDE_ADULT_CONTENT_SCREEN,
            element: <HideAdultContent />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_SET_RATING_LIMITS,
            element: <SetRatingLimits />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_HELP_SCREEN,
            element: <ParentalControlHelp />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.CLOUD_DVR_HELP_SCREEN,
            element: <CloudDVRHelp />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.ACCOUNT,
            element: <AccountScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.HELP_SCREEN,
            element: <HelpScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.INFO,
            element: <InfoScreen />,
        },
        {
            path: LocationUrls.MAIN_SETTINGS.PC_SCREEN,
            element: <ParentalControls />,
        },
    ];
    return (
        <Routes>
            {settingsRoutes.map((route) => {
                return <Route path={route.path} element={route.element} key={route.path} />;
            })}
        </Routes>
    );
};

export default SettingsRoutes;
