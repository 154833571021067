import React, { FunctionComponent, useEffect } from 'react';

import { Log } from '../../core/logging';
import { MouseOperations } from '../../core/navigation/MouseOperations';
import { useTivoDispatch } from '../../core/tivo/tivo-redux-wrapper';
import { CcbSwitch } from '../../core/utils/ccb';
import { CcbSwitchName } from '../../core/utils/ccb/enums';
import { clearBrowseStickinessInCollectionScreen } from '../../store/collectionscreen/collectionscreen.reducers';
import { onMouseEventAction } from '../../store/inputfactory/inputfactory.actions';
import {
    onMouseClick,
    onMouseEnter,
    onMouseLeave,
    onMouseOkLongPressStart,
    onMouseOkLongPressStop,
    onMouseOut,
    onMouseOver,
    onMouseWheel,
} from '../../store/inputfactory/inputfactory.reducers';

const MouseEvents: FunctionComponent = (): JSX.Element => {
    const TAG = 'MouseEvents';
    const dispatch = useTivoDispatch();
    const onMouseEventHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (!Object(e.target)?.classList?.contains('mousetrap')) return;
        dispatch(onMouseEventAction(e.type, e.target?.dataset?.value));
        Log.i(TAG, e.type);

        switch (e.type) {
            case MouseOperations.MOUSE_CONTEXT_MENU:
                // Perform right click (context menu) opeations
                break;
            case MouseOperations.MOUSE_DOWN:
                processOnMouseDownEvent();
                break;
            case MouseOperations.MOUSE_ENTER:
                if (
                    CcbSwitch.getInstance().isEnabledAndOverriden(
                        CcbSwitchName.ENABLE_COLLECTIONS_SCREEN_REMOVE_IN_KST_5277,
                    )
                ) {
                    dispatch(clearBrowseStickinessInCollectionScreen());
                }
                dispatch(onMouseEnter());
                break;
            case MouseOperations.MOUSE_LEAVE:
                dispatch(onMouseLeave());
                break;
            case MouseOperations.MOUSE_OUT:
                dispatch(onMouseOut());
                break;
            case MouseOperations.MOUSE_OVER:
                dispatch(onMouseOver());
                break;
            case MouseOperations.MOUSE_UP:
                processOnMouseUpEvent();
                break;
            case MouseOperations.MOUSE_WHEEL:
                dispatch(onMouseWheel(e.wheelDeltaY));
                break;
            default:
        }
    };

    const LONG_MOUSE_PRESS_THRESHOLD_TIME = 0.5 * 1000;
    let longPressTimerId: any = null;
    let wasLongPressEvent: boolean = false;

    const processOnMouseDownEvent = () => {
        longPressTimerId = setTimeout(() => {
            wasLongPressEvent = true;
            dispatch(onMouseOkLongPressStart());
        }, LONG_MOUSE_PRESS_THRESHOLD_TIME);
    };

    const processOnMouseUpEvent = () => {
        clearInterval(longPressTimerId);

        if (!wasLongPressEvent) {
            dispatch(onMouseClick());
        } else {
            dispatch(onMouseOkLongPressStop());
        }

        wasLongPressEvent = false;
    };

    useEffect(() => {
        document.addEventListener(MouseOperations.MOUSE_CLICK, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_CONTEXT_MENU, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_DOWN, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_ENTER, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_LEAVE, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_OUT, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_OVER, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_UP, onMouseEventHandler);
        document.addEventListener(MouseOperations.MOUSE_WHEEL, onMouseEventHandler, {
            passive: false,
        });
        return () => {
            document.removeEventListener(MouseOperations.MOUSE_CLICK, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_CONTEXT_MENU, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_DOWN, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_ENTER, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_LEAVE, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_OUT, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_OVER, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_UP, onMouseEventHandler);
            document.removeEventListener(MouseOperations.MOUSE_WHEEL, onMouseEventHandler);
        };
    }, []);

    return <></>;
};

export default MouseEvents;
